import React from 'react';
import PropTypes from 'prop-types';

export const ShippingInfo = ({ order, wordings }) => {
  const infosAddress = order.shipping_address
    .split('\n')
    .map((info, infoIndex) => (
      <span className="order-detail-content" key={infoIndex}>
        {info}
      </span>
    ));
  return (
    <div className="order-details-item order-details-shipping">
      <span className="order-detail-title shipping">
        {wordings.details_shipping_address}
      </span>
      {infosAddress}
    </div>
  );
};

ShippingInfo.propTypes = {
  order: PropTypes.object.isRequired,
  wordings: PropTypes.object.isRequired,
};
