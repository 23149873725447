import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
  AccountNav,
} from '../../../components/common';

import Layout from '../../../components/layout/layout';

import OrderDetails from '../../../components/account/orders/details/orderDetails';

class OrderDetailsPage extends Component {
  render() {
    const {
      data: {
        wordings: {
          fr: { orders, profile, date },
        },
      },
      location: { search },
    } = this.props;
    const orderWordings = {
      orders,
      date,
    };

    return (
      <Layout title={orders.page_title} description={orders.description}>
        <NavBar withLogo basketClassName="yellow" />
        <Basket />
        <Menu />
        <AccountNav wordings={profile} active="account_orders">
          <OrderDetails wordings={orderWordings} params={search} />
        </AccountNav>
        <Footer />
      </Layout>
    );
  }
}

OrderDetailsPage.defaultProps = {
  search: '',
};

OrderDetailsPage.propTypes = {
  data: PropTypes.object.isRequired,
  search: PropTypes.string,
};

export const query = graphql`
  query OrderDetailsPageQuery {
    wordings {
      fr {
        orders {
          page_title
          description
          list_prefix_date
          list_prefix_command_number
          details_download_bill
          details_shipping_address
          details_recap
          details_payment_means
          details_tva
          details_no_tax_price
          details_shipping_price
          details_total_price
          details_credit_card
        }
        profile {
          profile_link
          orders_link
        }
        date {
          month_january
          month_february
          month_march
          month_april
          month_may
          month_june
          month_july
          month_august
          month_september
          month_october
          month_november
          month_december
          day_monday
          day_tuesday
          day_wednesday
          day_thursday
          day_friday
          day_saturday
          day_sunday
        }
      }
    }
  }
`;

export default pageWithNavigation(OrderDetailsPage);
