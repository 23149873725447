import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../../../utils/stringOperations';

import Download from '../../../../assets/img/icons/arrow_download.png';

const { GATSBY_MONIMALZ_API_URL } = process.env;

export const OrderHeader = ({
  wordings: { orders: orderWordings, date: dateWordings },
  token,
  order,
}) => {
  let billUrl = '';
  if (order.bill_url) {
    billUrl = `${GATSBY_MONIMALZ_API_URL}${order.bill_url.substring(
      1
    )}?mnz_token=${token}`;
  }
  const orderId = order.uuid.slice(0, 8);
  const date = formatDate(order.created_at * 1000);

  return (
    <div className="order-header">
      <h1 className="order-command-number">
        {orderWordings.list_prefix_command_number} : #{orderId}
      </h1>
      <span className="order-command-date">
        {orderWordings.list_prefix_date} {date.day} {dateWordings[date.month]}{' '}
        {date.year}
      </span>
      {billUrl !== '' && (
        <a className="order-command-bill" href={billUrl} target="_blank">
          <span className="order-bill-download">
            {orderWordings.details_download_bill}
          </span>
          <img src={Download} className="logo-download" alt="download" />
        </a>
      )}
    </div>
  );
};

OrderHeader.propTypes = {
  order: PropTypes.object.isRequired,
  wordings: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};
