import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSingleOrder } from '../../../../actions/shopActions';

import {
  DetailsProduct,
  OrderHeader,
  ShippingInfo,
  PriceInfo,
  PaymentInfo,
} from '.';

import { getUrlParams } from '../../../../utils/stringOperations';
import { formatProducts } from '../shared/formatProducts';

import './details.styl';

class OrderDetails extends Component {
  state = {
    order: {},
  };

  componentDidMount() {
    const { params, token, getSingleOrder } = this.props;

    const paramsFormat = getUrlParams(params);
    if (!paramsFormat.cmdId) {
      navigate('account/orders');
    }
    getSingleOrder(token, paramsFormat.cmdId).then(order => {
      if (order !== false) this.setState({ order });
    });
  }

  render() {
    const { order } = this.state;
    const { wordings, token } = this.props;

    const isOrderFilled = Object.keys(order).length;

    const listProducts = !!isOrderFilled
      ? formatProducts(order, DetailsProduct)
      : null;

    return (
      !!isOrderFilled && (
        <div className="order-wrapper">
          <OrderHeader wordings={wordings} order={order} token={token} />
          <div className="order-command">
            <div className="order-products-wrapper">{listProducts}</div>
            <div className="order-details-wrapper">
              <ShippingInfo order={order} wordings={wordings.orders} />
              <PriceInfo order={order} wordings={wordings.orders} />
              <PaymentInfo order={order} wordings={wordings.orders} />
            </div>
          </div>
        </div>
      )
    );
  }
}

OrderDetails.defaultProps = {
  wordings: {},
  parmas: '',
};

OrderDetails.propTypes = {
  wordings: PropTypes.object,
  params: PropTypes.string,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = ({ account: { access_token } }) => ({
  token: access_token,
});

export default connect(
  mapStateToProps,
  { getSingleOrder }
)(OrderDetails);
