import React from 'react';
import PropTypes from 'prop-types';

import { formatPrices } from '../../../../utils/stringOperations';

export const DetailsProduct = ({ item }) => {
  const urlProduct = item.files
    .map(p => p.type === 'main' && p.url)
    .filter(url => url);
  const priceFormatted = formatPrices(item.price);

  return (
    <div className="order-products-item">
      <img className="order-item-image" src={urlProduct} alt="product" />
      <div className="order-item-infos">
        <span className="order-item-title">{item.name}</span>
        <span className="order-item-unit-price">{priceFormatted}</span>
      </div>
    </div>
  );
};

DetailsProduct.propTypes = {
  item: PropTypes.object.isRequired,
};
