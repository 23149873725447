import React from 'react';
import PropTypes from 'prop-types';

import { formatPrices } from '../../../../utils/stringOperations';

export const PriceInfo = ({ order, wordings }) => {
  const { vat, total_price, shipping_fee } = order;
  const arrayPrices = [
    { name: 'details_tva', value: vat },
    { name: 'details_shipping_price', value: shipping_fee },
    { name: 'details_total_price', value: total_price },
    { name: 'details_no_tax_price', value: total_price - vat },
  ];
  const listPrices = arrayPrices.map((row, indexRow) => {
    const formattedPrice = formatPrices(row.value);
    return (
      <div
        className="order-detail-content order-detail-item-container"
        id={name}
        key={indexRow}
      >
        <span className="order-detail-item">{wordings[row.name]} : </span>
        <span className="order-detail-item content-right">
          {formattedPrice}
        </span>
      </div>
    );
  });

  return (
    <div className="order-details-item order-details-recap">
      <span className="order-detail-title recap">{wordings.details_recap}</span>
      {listPrices}
    </div>
  );
};

PriceInfo.propTypes = {
  order: PropTypes.object.isRequired,
  wordings: PropTypes.object.isRequired,
};
