import React from 'react';
import PropTypes from 'prop-types';

import VisaLogo from '../../../../assets/img/icons/visa_logo.png';

export const PaymentInfo = ({ order, wordings }) => {
  const { payment_means } = order;

  return (
    <div className="order-details-item order-details-payment-means">
      <span className="order-detail-title payment-means">
        {wordings.details_payment_means}
      </span>
      <div className="order-detail-content order-detail-item-container payment-means">
        <img
          src={VisaLogo}
          alt="payment-means-brand"
          className="order-payment-means-brand"
        />
        {payment_means && payment_means.display_number && (
          <span className="order-detail-item ending-numbers">
            {`${wordings.details_credit_card} ${payment_means.display_number}`}
          </span>
        )}
      </div>
    </div>
  );
};

PaymentInfo.propTypes = {
  order: PropTypes.object.isRequired,
  wordings: PropTypes.object.isRequired,
};
